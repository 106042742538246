
<template>
  <wide-panel
    :title="title"
    :breadcrumbs="breadcrumbs"
  >
    <v-toolbar
      card
      extended
    >
      <v-container fluid>
        <v-layout row>
          <v-flex xs3>
            <v-select
              v-model="filter"
              :items="filters"
              item-text="text"
              item-value="value"
              label="Filter by"
            />
          </v-flex>
          <v-flex xs3>
            <v-text-field
              v-if="filter !== null"
              v-model="search"
              label="Enter search string"
              clearable
            />
          </v-flex>
          <v-flex xs1>
            <v-checkbox
              v-model="xox"
              label="XOX"
            />
          </v-flex>
        </v-layout>
      </v-container>
    </v-toolbar>
    <v-card>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :pagination.sync="pagination"
          :total-items="totalItems"
          :rows-per-page-items="[10, 25, 50, 100]"
          :expand="true"
          item-key="dealerId"
          class="px-1 pb-1"
        >
          <template #items="props">
            <tr @click="props.expanded = !props.expanded">
              <td
                v-if="props.item.dealerId"
                class="text-xs-left"
              >
                {{ props.item.dealerId }}
              </td>
              <td
                v-else
                class="text-xs-center"
              >
                -
              </td>
              <td
                v-if="props.item.name"
                class="text-xs-left"
              >
                {{ props.item.name }}
              </td>
              <td
                v-else
                class="text-xs-left"
              >
                -
              </td>
              <td
                v-if="props.item.msisdn"
                class="text-xs-left"
              >
                {{ props.item.msisdn }}
              </td>
              <td
                v-else
                class="text-xs-center"
              >
                -
              </td>
              <td
                v-if="props.item.mykad"
                class="text-xs-left"
              >
                {{ props.item.mykad }}
              </td>
              <td
                v-else
                class="text-xs-center"
              >
                -
              </td>
              <td
                v-if="props.item.dealership"
                class="text-xs-left"
              >
                {{ props.item.dealership }}
              </td>
              <td
                v-else
                class="text-xs-center"
              >
                -
              </td>
              <td
                v-if="props.item.date"
                class="text-xs-left"
              >
                {{ props.item.date }}
              </td>
              <td
                v-else
                class="text-xs-center"
              >
                -
              </td>
              <td
                v-if="props.item.status"
                class="text-xs-left"
              >
                {{ props.item.status }}
              </td>
              <td
                v-else
                class="text-xs-center"
              >
                -
              </td>
              <td
                v-if="props.item.kpiStartTerm"
                class="text-xs-center"
              >
                {{ props.item.kpiStartTerm }}
              </td>
              <td
                v-else
                class="text-xs-center"
              >
                -
              </td>
              <td
                v-if="props.item.demoteMonth"
                class="text-xs-center"
              >
                {{ props.item.demoteMonth }}
              </td>
              <td
                v-else
                class="text-xs-center"
              >
                -
              </td>
              <td
                v-if="props.item.finalPayoutMonth"
                class="text-xs-center"
              >
                {{ props.item.finalPayoutMonth }}
              </td>
              <td
                v-else
                class="text-xs-center"
              >
                -
              </td>
            </tr>
          </template>
          <template #expand="props">
            <v-card>
              <v-container
                fluid
                grid-list-xs
              >
                <v-layout
                  row
                  wrap
                >
                  <v-flex
                    d-flex
                    xs2
                  >
                    <v-navigation-drawer
                      dark
                      stateless
                      permanent
                      floating
                      value="true"
                      class="indigo lighten-1"
                    >
                      <v-list dense>
                        <v-list-tile
                          @click="record(props.item.id)"
                        >
                          <v-list-tile-content>
                            <v-list-tile-title color="orange">
                              E-recharge Record
                            </v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile
                          @click="annual(props.item.id)"
                        >
                          <v-list-tile-content>
                            <v-list-tile-title color="orange">
                              Annual Subscription
                            </v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                      </v-list>
                    </v-navigation-drawer>
                  </v-flex>
                  <v-flex xs10>
                    <v-layout
                      row
                      wrap
                    >
                      <v-flex xs3>
                        Introducer:
                      </v-flex>
                      <v-flex xs3>
                        <span class="accent--text">{{ props.item.upline }}</span>
                      </v-flex>
                      <v-flex xs3>
                        Total User:
                      </v-flex>
                      <v-flex xs3>
                        <span class="accent--text">{{ props.item.totalUser }}</span>
                      </v-flex>
                      <v-flex xs3>
                        Area Dealer:
                      </v-flex>
                      <v-flex xs3>
                        <span class="accent--text">{{ props.item.areaDealer }}</span>
                      </v-flex>
                      <v-flex xs3>
                        Master Dealer:
                      </v-flex>
                      <v-flex xs3>
                        <span class="accent--text">{{ props.item.masterDealer }}</span>
                      </v-flex>
                      <v-flex xs3>
                        Erecharge Balance:
                      </v-flex>
                      <v-flex xs3>
                        <span class="accent--text">{{ props.item.Erbalance }}</span>
                      </v-flex>
                      <v-flex xs3>
                        Erecharge Sales:
                      </v-flex>
                      <v-flex xs3>
                        <span class="accent--text">{{ props.item.Ersale }}</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
          </template>
          <template slot="no-data">
            <v-alert
              :value="true"
              :type="error ? 'error' : 'info'"
            >
              {{ noDataText }}
            </v-alert>
          </template>
          <template slot="no-result">
            <v-alert
              :value="true"
              type="info"
            >
              {{ noResultText }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="payoutMethodHistoryDialog"
      width="1000"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline"
          primary-title
        >
          E-recharge Record for {{ activeItem ? (activeItem.dealerId ? activeItem.dealerId : activeItem.msisdn) : 'Error' }}
        </v-card-title>
        <v-alert
          :value="alert"
          :type="alertType"
          transition="scale-transition"
        >
          {{ alertMessage }}
        </v-alert>
        <v-card-text>
          <v-toolbar card>
            <v-menu
              v-model="menu"
              lazy
              close-on-content-click
              transition="scale-transition"
              full-width
              min-width="290px"
            >
              <v-text-field
                slot="activator"
                v-model="monthOf"
                prepend-icon="event"
                readonly
              />
              <v-date-picker
                v-model="monthOf"
                :max="maxDate"
                :allowed-dates="allowedMonths"
                min="2014-01"
                type="month"
                year-icon="date_range"
              />
            </v-menu>
            <v-spacer />
            <v-text-field
              v-model="searchz"
              append-icon="search"
              label="Search entity"
              clearable
            />
          </v-toolbar>
          <v-data-table
            :headers="payoutMethodHistoryHeaders"
            :items="payoutMethodHistoryItems"
            :loading="loading"
            :rows-per-page-items="[5, 10, 25, 50]"
            class="elevation-1"
          >
            <template
              slot="items"
              slot-scope="props"
            >
              <tr>
                <td class="text-xs-left">
                  {{ props.item.entry }}
                </td>
                <td class="text-xs-left">
                  {{ props.item.action }}
                </td>
                <td
                  v-if="props.item.entity == 'DYDLR33'"
                  class="text-xs-left"
                >
                  LOCK
                </td>
                <td
                  v-else
                  class="text-xs-left"
                >
                  {{ props.item.entity }}
                </td>
                <td
                  v-if="props.item.debit"
                  class="text-xs-right"
                >
                  <span class="success--text">{{ props.item.amount }}</span>
                </td>
                <td
                  v-else-if="props.item.amount === '0.00'"
                  class="text-xs-right"
                >
                  <span class="info--text">{{ props.item.amount }}</span>
                </td>
                <td
                  v-else
                  class="text-xs-right"
                >
                  <span class="warning--text">{{ props.item.amount }}</span>
                </td>
              </tr>
            </template>

            <template slot="no-data">
              <v-alert
                :value="true"
                :type="error ? 'error' : 'info'"
              >
                {{ noDataText }}
              </v-alert>
            </template>
            <template slot="no-result">
              <v-alert
                :value="true"
                type="info"
              >
                {{ noResultText }}
              </v-alert>
            </template>
          </v-data-table>
          <v-card>
            <v-card-text>
              <v-subheader>Legends:</v-subheader>
              <v-chip
                dark
                small
              >
                <v-avatar class="success">
                  RM
                </v-avatar>
                <span class="success--text">Debit</span>
              </v-chip>
              <v-chip
                dark
                small
              >
                <v-avatar class="warning">
                  RM
                </v-avatar>
                <span class="warning--text">Credit</span>
              </v-chip>
              <v-chip
                dark
                small
              >
                <v-avatar class="info">
                  RM
                </v-avatar>
                <span class="info--text">Info</span>
              </v-chip>
            </v-card-text>
          </v-card>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              flat
              @click="payoutMethodHistoryDialog=false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="annualSubscriptionDialog"
      width="1000"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline"
          primary-title
        >
          Annual Subscription for {{ activeItem ? (activeItem.dealerId ? activeItem.dealerId : activeItem.msisdn) : 'Error' }}
        </v-card-title>
        <v-card-text>
          <v-data-table
            :items="annualSubscription"
            hide-actions
            hide-headers
          >
            <template #items="props">
              <v-layout
                row
                wrap
              >
                <v-flex xs3>
                  Enrolment Month:
                </v-flex>
                <v-flex xs7>
                  <td
                    class="text-xs-center"
                  >
                    {{ props.item.enrolment }}
                  </td>
                </v-flex>
                <v-flex xs3>
                  Total Activations:
                </v-flex>
                <v-flex xs7>
                  <td
                    class="text-xs-center"
                  >
                    {{ props.item.totalActivations }}
                  </td>
                </v-flex>
                <v-flex xs3>
                  Total Erecharge Sales:
                </v-flex>
                <v-flex xs7>
                  <td
                    class="text-xs-center"
                  >
                    {{ props.item.totalErecharge }}
                  </td>
                </v-flex>
                <v-flex xs3>
                  Expiry Month:
                </v-flex>
                <v-flex xs7>
                  <td
                    class="text-xs-center"
                  >
                    {{ props.item.demoteMonths }}
                  </td>
                </v-flex>
                <v-flex xs3>
                  Next Subscription Fee:
                </v-flex>
                <v-flex xs7>
                  <td
                    v-if="props.item.message === 'Waivable'"
                    class="green--text"
                  >
                    <strong>  {{ props.item.message }}</strong>
                  </td>
                  <td
                    v-else
                    class="red--text"
                  >
                    <strong>  {{ props.item.message }}</strong>
                  </td>
                </v-flex>
              </v-layout>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer />
            <v-btn
              flat
              @click="annualSubscriptionDialog=false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </wide-panel>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams } from '@/rest'
import security from '@/security'
// import Jimp from 'jimp'

const pageTitle = 'Dealer Terminated/ Demoted List'

export default {
  components: {
    WidePanel: () => import(/* webpackChunkName: "widePanel" */ '@/components/WidePanel'),
  },
  data () {
    return {
      allowedMonths: function (month) {
        const ym = month.split('-')
        const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
        const fromDate = DateTime.local(2016, 1, 1).setZone('Asia/Kuala_Lumpur')
        const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

        return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
      },
      breadcrumbs: [
        {
          text: 'DMT Kits', disabled: false, to: '/support/dmt_kits',
        },
        {
          text: pageTitle, disabled: true,
        },
      ],
      maxDate: '',
      menu: false,
      monthOf: null,
      activeItem: null,
      alert: false,
      alertType: null,
      alertMessage: null,
      xox: null,
      dealership: null,
      source: null,
      error: null,
      filter: null,
      filters: [
        {
          text: 'DealerId',
          value: 'dealerId',
        },
        {
          text: 'Msisdn',
          value: 'msisdn',
        },
        {
          text: 'MyKad',
          value: 'mykad',
        },

      ],
      headers: [
        {
          text: 'DealerId',
          align: 'left',
          sortable: true,
          value: 'dealerId',
        },
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Msisdn',
          align: 'left',
          sortable: true,
          value: 'msisdn',
        },
        {
          text: 'MyKad',
          align: 'left',
          sortable: false,
          value: 'mykad',
        },
        {
          text: 'Dealership',
          align: 'left',
          sortable: true,
          value: 'dealership',
        },
        {
          text: 'Active Date',
          align: 'left',
          sortable: true,
          value: 'date',
        },
        {
          text: 'Status',
          align: 'left',
          sortable: true,
          value: 'status',
        },
        {
          text: 'KPI Start Term',
          align: 'left',
          sortable: false,
          value: 'kpiStartTerm',
        },
        {
          text: 'Demote Month',
          align: 'center',
          sortable: false,
          value: 'demoteMonth',
        },
        {
          text: 'Final Payout Month',
          align: 'center',
          sortable: false,
          value: 'finalPayoutMonth',
        },
      ],
      items: [],
      annualSubscription: [],
      loading: false,
      noResultText: 'No data that fits that criteria.',
      onex: true,
      pagination: {},
      remark: null,
      search: null,
      searchId: null,
      searchIdz: null,
      searchz: '',
      searchEntity: '',
      title: pageTitle,
      totalItems: 0,
      payoutMethodHistoryDialog: false,
      annualSubscriptionDialog: false,
      payoutMethodHistoryHeaders: [
        {
          align: 'left',
          sortable: false,
          text: 'Date',
          value: 'entry',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Action',
          value: 'action',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Entity',
          value: 'entity',
        },
        {
          align: 'right',
          sortable: false,
          text: 'Amount',
          value: 'amount',
        },
      ],
      payoutMethodHistoryItems: [],
    }
  },
  computed: {
    canImpersonate: function () {
      return security.me.claimer() === 'onex' ? this.onex : !this.onex
    },
    noDataText: function () {
      return this.error ? this.error : "There's nothing to display."
    },
  },
  watch: {
    xox: function (val) {
      this.onex = !val
      this.refreshList()
    },
    filter: function () {
      (this.search) && this.resetList()
    },
    searchz: function (val) {
      clearTimeout(this.searchIdz)
      this.cancelRequest()
      if (val) {
        this.searchIdz = setTimeout(() => {
          this.searchEntity = this.searchz
        }, 1000)
      } else {
        this.searchEntity = ''
      }
    },
    searchEntity: function () {
      this.getErechargeRecords(this.monthOf)
    },
    search: function (val) {
      clearTimeout(this.searchId)
      this.cancelRequest()
      if (val) {
        this.searchId = setTimeout(() => {
          this.refreshList()
        }, 1000)
      } else {
        this.resetList()
      }
    },

    monthOf: function (val) {
      this.getErechargeRecords(val)
    },

    pagination: {
      handler () {
        this.refreshList()
      },
      deep: true,
    },
  },
  mounted: function () {
    this.xox = security.me.claimer() === 'xox'
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.month = this.monthOf = now.toFormat('yyyy-LL')
  },
  methods: {
    async getDealerList () {
      const filters = this.getFilters()
      if (!filters) return { items: [], totalItems: 0 }

      this.loading = true
      const { page, rowsPerPage } = this.pagination
      const parameters = {
        page,
        rowsPerPage,
      }
      Object.assign(parameters, filters)

      const apiParameters = createGetParams(parameters)
      try {
        this.source = this.$rest.CancelToken.source()
        Object.assign(apiParameters, { cancelToken: this.source.token })
        const response = await this.$rest.get('getDealerTerminated.php', apiParameters)
        this.error = null
        this.loading = false
        return response.data
      } catch (error) {
        this.loading = false
        if (!this.$rest.isCancel(error)) {
          this.error = error.message
        }
        return { items: [], totalItems: 0 }
      }
    },

    record (id) {
      this.resetParameters(id)
      this.payoutMethodHistoryDialog = true
      this.getErechargeRecords(id)
    },
    annual (id) {
      this.resetParameters(id)
      this.annualSubscriptionDialog = true
      this.getannualRecords(id)
    },
    getErechargeRecords: function (id) {
      this.getErechargeTransactions(id)
    },
    getannualRecords: function (id) {
      this.getannualSubscription(id)
    },
    async getErechargeTransactions () {
      try {
        const params = createGetParams({
          id: this.activeItem.id,
          target: this.activeItem.claimer,
          month: this.monthOf,
          search: this.searchEntity,
        })
        const response = await this.$rest.get('getErechargeRecordsTerminate.php', params)
        this.payoutMethodHistoryItems = response.data.items
      } catch (error) {
        let message = error.message
        if (error.response) {
          message += ': ' + error.response.data
        }
        this.showError(message)
      }
    },
    async getannualSubscription () {
      try {
        const params = createGetParams({
          id: this.activeItem.id,
          target: this.activeItem.claimer,
        })
        const response = await this.$rest.get('getAnnualTerminatedDealer.php', params)
        this.annualSubscription = response.data.items
      } catch (error) {
        let message = error.message
        if (error.response) {
          message += ': ' + error.response.data
        }
        this.showError(message)
      }
    },

    resetParameters (id) {
      this.alert = false
      const item = this.items.find(item => item.id === id)
      this.activeItem = item
    },
    getFilters () {
      const filterBy = {}

      if (this.filter === 'dealerId' && this.search) {
        Object.assign(filterBy, { dealerId: this.search })
      }

      if (this.filter === 'msisdn' && this.search) {
        Object.assign(filterBy, { msisdn: this.search })
      }

      if (this.filter === 'mykad' && this.search) {
        Object.assign(filterBy, { mykad: this.search })
      }

      Object.assign(filterBy, { onex: this.onex })

      return this.filter && this.search ? filterBy : null
    },
    cancelRequest: function () {
      this.loading = false
      if (this.source) {
        this.source.cancel('Forced cancellation.')
        this.source = null
      }
    },
    refreshList () {
      this.getDealerList().then(data => {
        this.items = data.items
        this.totalItems = data.totalItems
      })
    },
    resetList () {
      this.items = []
      this.totalItems = 0
    },
    showError (message) {
      this.alertType = 'error'
      this.alertMessage = message
      this.alert = true
    },
  },
}
</script>
